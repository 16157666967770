/* General Styling */
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }
  
  .landing-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: black;
    color: white;
    text-align: center;
    overflow: hidden;
  }
  
  /* Logo Styling */
  .landing-logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    max-height: 30%;
    z-index: 2;
  }
  
  /* Menu Portal Styling */
  .menu-portal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    max-height: 100vh;
    z-index: 1;
  }
  
  .menu-portal {
    width: 100%;
    max-height: 100vh;
    object-fit: cover;
  }
  
  /* Background Images Styling */
  .bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 0;
  }
  
  .background-image {
    position: absolute;
    top: 43%;
    left: 50%;
    width: 120%; /* Zoomed in to ensure no edges are visible */
    height: auto;
    transform: translate(-50%, -50%);
    object-fit: cover;
    animation: bg-animation 10s ease-in-out;
    animation-fill-mode: forwards;
  }
  
  /* Coming Soon Text */
  .coming-soon {
    position: absolute;
    bottom: 45%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 4.5rem;
    color: white;
    z-index: 3;
  }
  
  /* Background Image Animation */
  @keyframes bg-animation {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  @media (max-width: 1920px) {
    .background-image {
        top: 41%;
      }
    @keyframes bg-animation {
      0% {
        transform: translate(-50%, -50%) scale(1.8); /* Slightly zoomed-in starting point */
      }
      100% {
        transform: translate(-50%, -50%) scale(1.48); /* Ensures it remains covered by portal */
      }
    }
  }
  
  /* Mobile Styles */
  @media (max-width: 1920px) {
    /* Adjust logo size */
    .landing-logo {
      max-height: none; /* Removes fixed height for mobile */
      max-width: 30%; /* Ensures logo takes no more than 30% of screen width */
    }
  
    /* Menu Portal fills the screen height */
    .menu-portal-container {
      height: 100%; /* Menu portal covers full height of the screen */
      top: 0; /* Aligns to the top */
      left: 0; /* Aligns to the left */
      transform: none; /* Removes center alignment */
    }
  
    .menu-portal {
      width: 100%;
      height: 100%; /* Fills the screen height */
      object-fit: cover;
    }
  
    /* Adjust Coming Soon text position */
    .coming-soon {
      bottom: 30%; /* Pushes the text upwards for better visibility */
      font-size: 2rem; /* Slightly smaller text size on mobile */
    }
  }
  